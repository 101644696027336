import { NavLink, useNavigate } from "react-router";
import { Headline } from "components/UI/Headline";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/shadcn-ui/dialog.tsx";
import type React from "react";
import { useEffect, useState } from "react";
import { cn } from "utils/utils";

type Tab = {
  name: string;
  url: string;
  enabled: boolean;
};
export const ModalWrapper = ({
  isOpen = true,
  closeUrl = null,
  children,
  modalTitle,
  triggerComponent,
  childrenClassName,
  setIsOpen,
  tabs = [],
  footer,
  className,
}: {
  isOpen?: boolean;
  childrenClassName?: string;
  modalTitle: string | React.ReactNode;
  closeUrl?: string | null;
  children: React.ReactNode;
  className?: string;
  triggerComponent?: React.ReactNode;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  tabs?: Tab[];
  footer?: React.ReactNode;
}) => {
  const navigate = useNavigate();

  const handleClose = () => {
    if (closeUrl) {
      navigate(`${closeUrl}`);
    }
    setIsOpen?.(false);
  };
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // ComponentDidMount lifecycle only on client-side
    setIsClient(true);
  }, []);

  if (!isClient) {
    // Or return a loading state or similar here if desired
    return null;
  }
  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      {triggerComponent}

      <DialogContent
        className={cn(
          "h-fit max-h-[95dvh] max-w-[96%] p-0 lg:w-[800px] overflow-auto lg:max-w-[800px]",
          className,
        )}
      >
        <DialogHeader className="border-b border-gray-200">
          <TabsWrapper modalTitle={modalTitle} tabs={tabs}></TabsWrapper>
        </DialogHeader>
        <div
          className={cn(
            "max-h-[80vh] overflow-auto flex flex-col p-5 lg:px-10 lg:pt-6",
            childrenClassName,
          )}
        >
          {children}
        </div>
        {footer ? (
          <DialogFooter className="flex w-full items-center justify-center p-5 lg:p-10">
            {footer}
          </DialogFooter>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};
const TabsWrapper = ({
  tabs,
  children,
  modalTitle,
}: {
  tabs: Tab[];
  modalTitle?: string | React.ReactNode;
  children?: React.ReactNode;
}) => {
  if (tabs.length === 0)
    return (
      <>
        <DialogTitle className="self-start p-5 text-start lg:px-10 lg:py-6">
          <Headline size="h4">{modalTitle}</Headline>
        </DialogTitle>
        {/* <div className="mt-8 h-1 w-32 bg-teal"></div> */}
        {children ? (
          <div className="size-full max-h-[80vh] overflow-y-auto px-10 py-6">
            {children}
          </div>
        ) : null}
      </>
    );
  return (
    <>
      <div className="border-b-iron flex flex-row gap-x-6 border-b-2 text-sm">
        {tabs.map((tab) => {
          if (!tab.enabled) {
            return (
              <span
                key={tab.url}
                className="border-b-iron cursor-default border-b px-1.5 py-3 opacity-50"
              >
                {tab.name}
              </span>
            );
          }
          return (
            <NavLink
              key={tab.url}
              to={tab.url}
              prefetch="intent"
              preventScrollReset
              className={({ isActive }) =>
                `px-1.5 py-3 ${
                  isActive
                    ? "border-b-teal border-b-2 font-semibold"
                    : "border-b-iron border-b"
                }`
              }
            >
              {tab.name}
            </NavLink>
          );
        })}
      </div>
      {children ? (
        <div className="size-full max-h-[80vh] overflow-y-auto pt-2 lg:pt-4">
          {children}
        </div>
      ) : null}
    </>
  );
};
